@use 'variables';

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  max-width: 365px !important;
  background-color: $background;
}

body.dark {
  .mat-mdc-menu-panel.mat-mdc-menu-panel {
    background-color: $darkBackgroundExpansionPanelLike;
  }
}

alfa-help-menu {
  .mat-mdc-fab {
    box-shadow: none !important;
    background-color: transparent !important;
    border-radius: 10px;
    height: 40px;
    width: auto;
    padding: 0.5rem;
    font-size: 0.875rem;
  }
}
