/**
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
.fixed-dialog {
  .mat-mdc-dialog-container {
    padding: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
    .mat-mdc-dialog-title {
      display: flex;
      background-color: $dark-background;
      margin: 0;
      padding: 0 4px 0 16px;
      color: #fff;
      font-size: 1rem;
      font-weight: normal;
      justify-content: space-between;
      align-items: center;
    }
    .mdc-dialog__title::before {
      display: none;
    }
    .mat-mdc-icon-button.mat-mdc-button-base {
      padding: 0;
      width: 40px;
      height: 40px;
    }
    .mdc-text-field,
    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      padding: 0;
    }
    .mat-mdc-form-field-error {
      font-size: 0.75rem;
    }
    .mdc-dialog__content {
      line-height: 20px;
    }
    .mat-mdc-dialog-content {
      max-height: 50vh;
      min-height: 200px;
      margin: 0 0 58px 0;
      padding: 8px 16px;

      > * {
        background-color: inherit;
      }

      &.minimized {
        max-height: 0;
        min-height: initial;
        overflow: hidden;
        margin: 0;
        padding: 0 !important;

        .button-bar-bottom {
          height: 0;
          overflow: hidden;
          padding: 0;
        }
      }
    }

    .mat-mdc-dialog-actions {
      min-height: initial;
      margin-bottom: 0;
    }

    .button-bar .mat-icon {
      padding: 8px 0;
    }

    .minimized .mat-icon {
      transform: rotate(180deg);
      padding: 0;
    }

    .button-bar-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 16px 16px 16px;
      display: flex;
      align-items: center;
    }
  }
}

body.dark {
  .fixed-dialog {
    .mat-mdc-dialog-title {
      background-color: $background;
      color: $dark-background;
    }
  }
}
