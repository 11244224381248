@use 'sass:map';
@use '@angular/material' as mat;

@mixin button($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, 'primary');

  ozgcloud-button-with-spinner,
  ozgcloud-stroked-button-with-spinner {
    .mat-primary svg path {
      fill: mat.get-color-from-palette($primary);
    }
  }

  .mdc-button {
    mat-icon {
      font-size: 24px !important;
      width: 24px !important;
      height: 24px !important;
    }
  }

  .mat-mdc-unelevated-button > .mat-icon,
  .mat-mdc-raised-button > .mat-icon,
  .mat-mdc-outlined-button > .mat-icon {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

// Necessary since Angular 17 - for mat-button-toggle
.mat-pseudo-checkbox {
   display: none !important;
}