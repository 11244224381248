/**
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
@use 'sass:map';
@use '@angular/material' as mat;

alfa-vorgang-search-container {
  .search-field {
    box-shadow: 0 0 0 1px rgba(#000, 0.08);
    background-color: $background;

    &:hover,
    &:focus-within {
      box-shadow: 0 1px 4px 0 rgba(#000, 0.4);
      background-color: #fff;
    }
  }

  input::placeholder {
    color: rgba(#000, 0.2);
  }
}

body.dark alfa-vorgang-search-container {
  .search-field {
    background-color: $dark-background;
  }

  input::placeholder {
    color: rgba(#fff, 0.2);
  }
}

@mixin search-container($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, 'primary');

  alfa-vorgang-search-container {
    .search-icon {
      color: mat.get-color-from-palette($primary);
    }
  }
}
