body.dark {
  .mdc-list-item--with-leading-icon .mdc-list-item__start,
  .mdc-list-item__secondary-text {
    color: white !important;
  }
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.87) !important;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start {
  margin: 0 !important;
  line-height: 24px !important;
}

.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line,
.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: auto !important;
}

.mdc-list-item__secondary-text::before {
  height: auto !important;
}

.mdc-list-item__content {
  padding-left: 6px;
}

.mdc-list-item {
  margin-bottom: 8px;
}
