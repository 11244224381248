.mdc-button,
.mdc-tab,
.mdc-fab--extended,
.mat-mdc-form-field,
.mat-mdc-floating-label,
.mat-mdc-menu-content,
.mdc-list-item__primary-text,
.mat-mdc-dialog-container .mdc-dialog__content {
  letter-spacing: normal !important;
}
