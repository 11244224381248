alfa-vorgang-search {
  .mdc-text-field--filled,
  .mdc-text-field--filled:hover {
    .mdc-line-ripple::before,
    .mdc-line-ripple::after {
      border-bottom: none;
    }
  }

  .mat-mdc-form-field-focus-overlay,
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
  }
}

ozgcloud-fixed-dialog {
  .mat-mdc-form-field-focus-overlay,
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
  }

  .mdc-text-field {
    padding: 0;
  }
}

body.dark {
  mat-form-field {
    --mdc-theme-error: theme('colors.error');
    --mat-form-field-error-text-color: theme('colors.error');
    --mdc-filled-text-field-error-focus-label-text-color: theme('colors.error');
    --mdc-outlined-text-field-error-focus-label-text-color: theme('colors.error');
    --mdc-filled-text-field-error-label-text-color: theme('colors.error');
    --mdc-outlined-text-field-error-label-text-color: theme('colors.error');
    --mdc-filled-text-field-disabled-active-indicator-color: theme('colors.error');
  }
}
